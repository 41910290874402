import { animated } from 'react-spring';
import styled from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { units } from '@calm-web/design-system';

export const NavMessageWrapper = styled(animated.div)<{
	$isError: boolean;
}>`
	position: fixed;
	top: calc(${theme('navigation.height')}px + 5px);
	width: auto;
	max-width: 600px;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	background: ${ifProp('$isError', palette('errorBackground'), palette('successBackground'))};
	text-align: center;
	padding: ${units(1, 2)};
	border-radius: ${theme('layout.borderRadius')};
	color: white;
	z-index: ${props => theme('modal.zIndex')(props) + 1};
`;
